import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PricingItem = ({ name, description, logo, usage, onClick }) => {

    return (<a
        class="relative block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring"
        onClick={() => { onClick(); }}
    >
        <span
            class="absolute whitespace-nowrap right-4 rounded-full bg-purple-100 justify-self-end px-2.5 py-0.5 text-sm text-purple-500"
        >
            {usage}x
        </span>
        <br />
        <span class="inline-block rounded-lg bg-gray-50 p-3" dangerouslySetInnerHTML={{ __html: logo }} >
        </span>

        <h2 class="mt-2 font-bold">{name}</h2>

        <p class="hidden sm:mt-1 sm:block sm:text-sm sm:text-gray-600">
            {description}
        </p>

    </a>);

}

const IndexPage = () => {

    let amount = 0;
    const items = require('../../data/products.json');
    let [usage, setUsage] = React.useState(new Array(items.length).fill(0));
    let [popup, setPopup] = React.useState(-1);
    let [monthUse, setMonthUse] = React.useState(0);

    const usageCost = items.map((item, i) => {

        amount += usage[i] * item.pricePerUnit;

        if (usage[i] == 0) return null;

        return <p class="flex"><strong class="inline-block">{item.name}:</strong> <p class="inline-block w-full text-right">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(usage[i] * item.pricePerUnit)}</p></p>

    }).filter((item) => item != null)

    return <Layout>
        {
            (popup != -1) &&
            <div class="fixed z-20 h-min w-1/3 m-auto inset-x-0 inset-y-0 p-8 bg-white rounded-lg shadow-2xl">
                <h2 class="text-lg font-bold">{items[popup].name}</h2>

                <p class="mt-2 text-sm text-gray-500">
                    How many times per month will you be using functions or<br /> functionality associated with {items[popup].name}?
                </p>
                <br />
                <label
                    for="UsageAmount"
                    class="relative block overflow-hidden rounded-md border border-gray-200 px-3 pt-3 shadow-sm focus-within:border-blue-600 focus-within:ring-1 focus-within:ring-blue-600"
                >
                    <input
                        type="integer"
                        id="UsageAmount"
                        placeholder="Usage Per Month"
                        class="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                        value={monthUse}
                        onChange={(e) => { setMonthUse(e.target.value) }}
                    />

                    <span
                        class="absolute left-3 top-2 -translate-y-1/2 text-xs text-gray-700 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs"
                    >
                        Usage Per Month
                    </span>
                </label>

                <div class="mt-8 flex items-center justify-end text-xs">
                    <button
                        type="button"
                        class="rounded bg-green-50 px-4 py-2 font-medium text-green-600"
                        onClick={() => { usage[popup] = Math.abs(Math.floor(monthUse.replace(/[^0-9]/g, ""))); if (typeof usage[popup] != "number") usage[popup] = 0; setUsage(usage); setPopup(-1); setMonthUse(0); }}
                    >
                        Calculate
                    </button>
                    <button
                        type="button"
                        class="ml-2 rounded bg-gray-50 px-4 py-2 font-medium text-gray-600"
                        onClick={() => { setPopup(-1); setMonthUse(0); }}
                    >
                        Close
                    </button>
                </div>
            </div>
        }
        <section class="bg-white">
            <div
                class="mx-auto max-w-screen-xl px-4 py-48 lg:flex lg:h-max lg:items-center"
            >
                <div class="mx-auto max-w-xl text-center">
                    <h1 class="text-3xl font-extrabold sm:text-5xl">
                        Simple, Scaled Pricing.
                    </h1>
                    <p class="mt-4 sm:text-xl sm:leading-relaxed">
                        To calculate Irishman's estimated cost, please enter the usage of each service per month.
                    </p>
                </div>
            </div>
            <div
                class="border-t-2 mx-auto max-w-screen-xl px-4 lg:flex lg:h-max lg:items-center align-top"
            >
                {/* Left */}
                <div class="w-3/4 p-4 grid grid-cols-2 gap-4 sm:grid-cols-3">
                    {
                        items.map((item, i) => {

                            return PricingItem({
                                ...item, usage: usage[i], onClick: () => {

                                    setPopup(i);
                                    setMonthUse(usage[i]);

                                }
                            })

                        })
                    }
                </div>
                {/* Right */}
                <div class="w-1/4 p-4 self-start">
                    <h2 class="uppercase text-md font-bold text-gray-800">Estimated Costs</h2>
                    <p class="italic text-sm text-gray-800">Please note, these are just estimates. Actual billing figures will be included in monthly payments.</p>
                    <div class="h-4"></div>
                    {usageCost}
                    <div class="h-4"></div>
                    <p class="flex border-t-[1px]"><strong class="inline-block">Total:</strong> <p class="inline-block w-full text-right">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)}</p></p>
                    <p class="italic text-sm text-gray-800">Not including tax.</p>
                </div>
            </div>
            <div class="mt-40"></div>
        </section>
    </Layout>
}

export const Head = () => <Seo title="Pricing" />

export default IndexPage
